<template>
  <!-- border-bottom: 2px solid #000; -->
  <div class="pc-nav" :style="{background:bgColor,borderBottom: `2px solid ${color}`}">
    <ul>
      <li v-for="item in navList" :key="item.id" :style="{color:color}" @mouseover="item.show = false"
        @mouseleave="item.show = true" @click="taget(item.id)">
        <!-- @mouseover="mouseOver" @mouseleave="mouseLeave" -->
        <p class="span1" v-show="item.show">
          <span v-if="item.sign" :style="{background:color}"></span>
          {{item.name}}
        </p>
        <p class="span2" v-show="!item.show">{{item.name2}}</p>
        <img v-if="color == '#4aaeff'" :src="item.url" alt="">
        <img v-if="color == '#ff7030'" :src="item.url2" alt="">
        <img v-if="color == '#64471a'" :src="item.url3" alt="">
        <img v-if="color == '#0a9161'" :src="item.url4" alt="">
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  name: "panav",
  props: ["color", "bgColor"],
  data() {
    return {
      navList: [
        {
          id: 1,
          name: "SHOP ALL",
          name2: "产品",
          sign: false,
          show: true,
        },
        {
          id: 2,
          name: "BE OUR PUZZ-BUD",
          name2: "拼朋",
          sign: false,
          show: true,
        },
        {
          id: 3,
          url: require("@/assets/img/10.svg"),
          url2: require("@/assets/img/12.svg"),
          url3: require("@/assets/img/13.svg"),
          url4: require("@/assets/img/icon.svg"),
        },
        {
          id: 4,
          name: "OUR STORIES",
          name2: "动态",
          sign: false,
          show: true,
        },
        {
          id: 5,
          name: "FIND US",
          name2: "关注",
          sign: false,
          show: true,
        },
      ],
      hover: "",
      stylp1: "",
      path: "",
      showp1: true,
      showp2: false,
    };
  },
  created() {
    this.path = this.$route.fullPath;
  },
  watch: {
    path(value) {
      if (value == "/desksidea") {
        this.navList[0].sign = true;
      } else if (value == "/side") {
        this.navList[1].sign = true;
      } else if (value == "/project") {
        this.navList[3].sign = true;
      } else if (value == "/deskabout") {
        this.navList[4].sign = true;
      }
    },
  },
  methods: {
    // mouseOver(id) {
    //   this.showp1 = false;
    //   this.showp2 = true;
    // },
    // mouseLeave() {
    //   this.showp1 = true;
    //   this.showp2 = false;
    // },
    taget(id) {
      console.log(id);
      if (id === 1) {
        this.$router.push({ path: "/desksidea" });
      } else if (id == 4) {
        this.$router.push({ path: "/project" });
      } else if (id == 2) {
        this.$router.push({ path: "/side" });
      } else if (id == 3) {
        this.$router.push({ path: "/" });
      } else if (id == 5) {
        this.$router.push({ path: "/deskabout" });
      }
    },
  },
};
</script>

<style lang="less">
.pc-nav {
  height: 0.75rem;
  width: 100%;
  border-bottom: 1;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 999;
  ul {
    display: flex;
    height: 0.75rem;
    li {
      width: 20%;
      text-align: center;
      line-height: 0.6rem;
      font-size: 0.27rem /* 27/100 */;
      font-weight: bold;
      position: relative;
      font-family: PHANBO, GLEXBL; //导航栏、筛选模块、底部跑马灯字体
      white-space: nowrap;
      img {
        width: 0.48rem /* 48/100 */;
        margin-top: 0.17rem /* 15/100 */;
        cursor: pointer;
      }
      p {
        width: 100%;
        cursor: pointer;
        transition: 0.5s all;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .span1 {
        top: 50%;
        // background: #000;
        span {
          background: #4aaeff;
          display: inline-block;
          width: 0.23rem /* 30/100 */;
          height: 0.23rem /* 30/100 */;
          border-radius: 50%;
          margin-right: 0.15rem /* 15/100 */;
        }
      }
      .span2 {
        top: 50%;
      }
      div {
        width: 0.3rem /* 30/100 */;
        height: 0.3rem /* 30/100 */;
        background: #4aaeff;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
}
</style>
