import { render, staticRenderFns } from "./deskSidea.vue?vue&type=template&id=15e2b76e&scoped=true&"
import script from "./deskSidea.vue?vue&type=script&lang=js&"
export * from "./deskSidea.vue?vue&type=script&lang=js&"
import style0 from "./deskSidea.vue?vue&type=style&index=0&id=15e2b76e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15e2b76e",
  null
  
)

export default component.exports