<template>
  <div class="desks-sidea">
    <panav :color='color' :bgColor='bgColor' />
    <header>
      <div class="left">
        <img src="~img/20.png" v-if="leftshow" class="left-up" @click="leftshow = false;rightshow = true">
        <img src="~img/19.png" v-if="!leftshow" class="left-down" @click="leftshow = true">
        <div class="left-scroll" v-if="!leftshow">
          <ul>
            <!-- <li @click="all(1)">全部 ALL</li> -->
            <li v-for="item in classList" :key="item.id" :class="{active:item.id == id}" @click="chanPin(item.id)">
              {{item.name}}</li>
          </ul>
        </div>
      </div>
      <div class="right">
        <img src="~img/21.png" v-if="rightshow" class="right-up" @click="rightshow = false; leftshow = true">
        <img src="~img/18.png" v-if="!rightshow" class="right-down" @click="rightshow = true">
        <div class="right-scroll" v-if="!rightshow">
          <ul>
            <!-- <li @click="all(2)">全部 ALL</li> -->
            <li v-for="item in artistList" :key="item.id" :class="{active:item.id == id}" @click="yiShu(item.id)">
              {{item.name}}</li>
          </ul>
        </div>
      </div>
    </header>
    <main>
      <ul class="masonry">
        <li class="item" v-for="item in list" :key="item.id" @click="Jump(item.url)">
          <!--  -->
          <img v-show="showMohu && item.is_show == true" :src="item.image" :key="item.id"
            :style="{width:+item.imagewidth + 'px'}" class="mohu">
          <img :src="item.image" :key="item.image" :style="{width:+item.imagewidth + 'px'}" />
        </li>
      </ul>
      <!-- <ul class="masonry">
        <li class="item" v-for="item in domelist" :key="item.id">
          <img :src="item.url" :key="item.url" :style="{width:item.width + 'px'}" />
        </li>
      </ul> -->
      <transition name="el-fade-in-linear">
        <img src="~img/39.svg" class="logo" v-show="showLogo">
      </transition>
      <p class="beian">© 2022 富富 SIDE PROJECT</p>

    </main>
    <horserace :color='noticeColor' :background='background' />
  </div>
</template>
<script>
import panav from "@/components/pc/nav.vue";
import horserace from "@/components/pc/horserace.vue";
import Masonry from "masonry-layout";
import imagesloaded from "imagesloaded"; // 引入imagesloaded库
export default {
  components: {
    panav,
    horserace,
  },
  data() {
    return {
      list: [],
      color: "#ff7030",
      leftshow: true,
      rightshow: true,
      classList: [], // 产品分类列表
      artistList: [], //艺术家分类
      noticeColor: "#fff",
      background: "#ff7030",
      bgColor: "#fff",
      col: "",
      domelist: [
        {
          url: require("../../assets/img/img.jpg"),
          width: 200,
          height: 60,
          id: 1,
        },
        {
          url: require("../../assets/img/img.jpg"),
          width: 300,
          height: 100,
          id: 2,
        },
        {
          url: require("../../assets/img/img.jpg"),
          width: 200,
          height: 100,
          id: 3,
        },
        {
          url: require("../../assets/img/img.jpg"),
          width: 200,
          height: 200,
          id: 4,
        },
        {
          url: require("../../assets/img/img.jpg"),
          width: 400,
          height: 200,
          id: 5,
        },
        {
          url: require("../../assets/img/img.jpg"),
          width: 200,
          height: 60,
          id: 6,
        },
        {
          url: require("../../assets/img/img.jpg"),
          width: 900,
          height: 60,
          id: 7,
        },
        {
          url: require("../../assets/img/img.jpg"),
          width: 50,
          height: 60,
          id: 8,
        },
        {
          url: require("../../assets/img/img.jpg"),
          width: 200,
          height: 60,
          id: 9,
        },
        {
          url: require("../../assets/img/img.jpg"),
          width: 300,
          height: 100,
          id: 10,
        },
        {
          url: require("../../assets/img/img.jpg"),
          width: 200,
          height: 100,
          id: 11,
        },
        {
          url: require("../../assets/img/img.jpg"),
          width: 200,
          height: 200,
          id: 12,
        },
        {
          url: require("../../assets/img/img.jpg"),
          width: 400,
          height: 200,
          id: 13,
        },
        {
          url: require("../../assets/img/img.jpg"),
          width: 200,
          height: 60,
          id: 14,
        },
        {
          url: require("../../assets/img/img.jpg"),
          width: 900,
          height: 60,
          id: 15,
        },
        {
          url: require("../../assets/img/img.jpg"),
          width: 50,
          height: 60,
          id: 16,
        },
      ],
      oldScrollTop: 0, //记录上一次滚动结束后的滚动距离
      scrollTop: 0, // 记录当前的滚动距离
      showLogo: false,
      msy: null,
      page: 1,
      showMohu: true,
      classify_id: "",
      artist_id: "",
      id: "",
    };
  },
  created() {
    this.id = this.getQueryValue("id");
    let name = this.getQueryValue("name");
    if (this.id) {
      if (name == "chanpin") {
        this.getProduct(this.id, "");
      } else {
        this.getProduct("", this.id);
      }
    } else {
      this.getProduct();
    }
    this.getclassify(); // 产品分类
    this.getartist(); // 艺术家分类
  },
  mounted() {
    this.handleScroll();
  },
  updated() {
    // this.msy.layout(); // 图片加载完成后重新绘制。
  },
  watch: {
    scrollTop(newValue, oldValue) {
      setTimeout(() => {
        if (newValue == window.scrollY) {
          //延时执行后当newValue等于window.scrollY，代表滚动结束
          // console.log("滚动结束");
          this.oldScrollTop = newValue; //每次滚动结束后都要给oldScrollTop赋值
          this.showLogo = false;
        }
      }, 300); //必须使用延时器，否则每次newValue和window.scrollY都相等，无法判断，20ms刚好大于watch的侦听周期，故延时20ms
      if (this.oldScrollTop == oldValue) {
        //每次滚动开始时oldScrollTop与oldValue相等
        // console.log("滚动开始");
        this.showLogo = true;
      }
    },
  },
  methods: {
    handleScroll() {
      window.addEventListener("scroll", () => {
        this.scrollTop = window.scrollY;
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        //变量windowHeight是可视区的高度
        let windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        //变量scrollHeight是滚动条的总高度
        let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        //滚动条到底部的条件
        if (scrollTop + windowHeight == scrollHeight) {
          //你想做的事情
          console.log("触底");
          this.page++;
          this.getProduct(this.classify_id, this.artist_id);
        }
      });
    },
    msnry() {
      this.masonry = document.querySelector(".masonry");
      this.msy = new Masonry(this.masonry, {
        columnWidth: 100,
        itemSelector: ".item",
        gutter: 10,
      });
      imagesloaded(".item", () => {
        this.msy.layout(); // 图片加载完成后重新绘制。
      });
    },
    async getProduct(classify_id, artist_id, num) {
      if (this.list.length > 0) {
        this.list.forEach((item) => {
          if (item.is_show) {
            item.is_show = false;
          }
        });
      }
      this.showMohu = true;
      const res = await this.axios.post(
        `/api/index/product?page=${this.page}`,
        {
          classify_id: classify_id,
          artist_id: artist_id,
          all: num,
        }
      );
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;

        data.data.forEach((value) => {
          this.list.push(value);
        });

        this.leftshow = true;
        this.rightshow = true;
        this.$nextTick(() => {
          this.msnry();
        });
        setTimeout(() => {
          this.showMohu = false;
        }, 1000);
      }
    },
    all(num) {
      this.getProduct("", "", num);
    },
    switchCol(col) {
      this.col = col;
      console.log(this.col);
    },
    Jump(url) {
      console.log("点击");
      // location.href = url;
      window.open(url);
    },
    async getclassify() {
      const res = await this.axios.get("/api/index/classify");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        this.classList = data;
        console.log(this.classList[0].id);
        // this.chanPin(this.classList[0].id);
      }
    },
    async getartist() {
      const res = await this.axios.get("/api/index/artist");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        this.artistList = data;
      }
    },
    chanPin(id) {
      this.page = 1;
      this.list = [];
      this.artist_id = "";
      this.classify_id = id;
      this.getProduct(id, "");
      this.id = id;
      this.$router.push({ path: `desksidea?id=${id}&name=chanpin` });
    },
    yiShu(id) {
      this.page = 1;
      this.list = [];
      this.classify_id = "";
      this.artist_id = id;
      this.getProduct("", id);
      this.id = id;
      this.$router.push({ path: `desksidea?id=${id}&name=yishu` });
    },
  },
  destroyed() {
    // window.removeEventListener("scroll", true);
  },
};
</script>


<style lang="less" scoped>
.desks-sidea {
  // min-width: 1480px;
  background-color: #fff;
  height: 100%;
  .logo {
    width: 5rem /* 818/100 */;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  header {
    display: flex;
    top: 100px;
    position: fixed;
    width: 100%;
    z-index: 999;
    .left {
      width: 50%;
      position: relative;
      height: 0.75rem /* 50/100 */;
      // background: #000;
      .left-up {
        position: absolute;
        left: 0.32rem /* 32/100 */;
        cursor: pointer;
        z-index: 99;
        width: 0.35rem;
      }
      .left-down {
        position: absolute;
        left: 0.32rem /* 32/100 */;
        top: 0.05rem;
        cursor: pointer;
        z-index: 99;
        width: 0.35rem;
      }
      .left-scroll {
        width: 400px;
        background: #a8f796;
        border-radius: 15px /* 15/100 */;
        position: absolute;
        left: 0.4rem /* 20/100 */;
        top: 0.25rem /* 25/100 */;
        z-index: 88;
        // transform: rotateY(45deg);
        transform: rotateZ(-5deg);
        padding-bottom: 20px /* 20/100 */;
        ul {
          padding-top: 50px /* 50/100 */;
          padding-left: 20px /* 20/100 */;
          li {
            font-size: 22px; /* 26/100 */
            font-weight: bold;
            color: #000;
            margin-bottom: 10px /* 10/100 */;
            cursor: pointer;
            transition: 0.2s all;
            font-family: PHANBO, GLEXBL; //导航栏、筛选模块、底部跑马灯字体
          }
          li:hover {
            color: #fff;
          }
          .active {
            color: #fff;
          }
        }
      }
    }
    .right {
      width: 50%;
      height: 0.75rem;
      position: relative;
      // background: palevioletred;
      .right-up {
        position: absolute;
        right: 0.32rem /* 32/100 */;
        cursor: pointer;
        z-index: 9;
        width: 0.35rem;
      }
      .right-down {
        position: absolute;
        right: 0.32rem /* 32/100 */;
        top: 0.05rem;
        cursor: pointer;
        z-index: 9;
        width: 0.35rem;
      }
      .right-scroll {
        width: 400px;
        background: #ffd7d6;
        border-radius: 0.15rem /* 15/100 */;
        position: absolute;
        right: 0.4rem /* 20/100 */;
        top: 0.25rem /* 25/100 */;
        z-index: 8;
        transform: rotateZ(5deg);
        padding-bottom: 20px /* 20/100 */;
        ul {
          padding-top: 50px /* 50/100 */;
          padding-left: 20px /* 20/100 */;
          li {
            font-size: 22px; /* 26/100 */
            font-weight: bold;
            color: #000;
            margin-bottom: 10px /* 10/100 */;
            cursor: pointer;
            transition: 0.2s all;
            font-family: PHANBO, GLEXBL; //导航栏、筛选模块、底部跑马灯字体
          }
          li:hover {
            color: #fff;
          }
          .active {
            color: #fff;
          }
        }
      }
    }
  }
  main {
    .beian {
      text-align: center;
      color: #ff7030;
      font-size: 0.13rem;
      line-height: 0.45rem;
    }
    margin-top: 0.5rem /* 50/100 */;
    padding-bottom: 0.6rem;
    // margin-bottom: 1rem;
    padding-top: 2rem;
    background: #fff;
    ul {
      background: #fff;
      padding-left: 1rem;
      max-height: 95%;
      li {
        img {
          cursor: pointer;
        }
        .mohu {
          position: absolute;
          filter: blur(
            10px
          ); /* 模糊效果，与宽高搭配做适当调整，把白边推出边界隐藏掉 */
        }
      }
    }
    .item {
      margin-bottom: 0.2rem;
    }
    @media screen and (max-width: 900px) {
      .item {
        width: 100% !important;
      }
    }
  }
  /deep/.vue-waterfall {
    img {
      // width: 60%;
      max-width: 90%;
      display: block;
      margin: auto;
      margin-bottom: 0.2rem;
    }
  }
}
</style>
